@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sen:wght@400..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,100..900;1,100..900&family=Sen:wght@400..800&display=swap');

/*****************************
********General Style*********
*****************************/
body.page {
    font-family: "Inter", sans-serif;
    background: #012B27;
}

img {
    width: 100%;
}

.page a {
    text-decoration: none;
    color: #EEEFF4;
}

a:hover {
    color: #3CBD27 !important;
}

.page ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.hidden {
    display: none;
}

/*****************************
********Container*********
*****************************/
.page .container {
    padding: 0 120px;
    max-width: 100%;
}

/*****************************
********Form Elements*********
*****************************/
input,
textarea 
{
    background: #fff;
    border: 0;
    border-radius: 8px;
    height: 48px;
    padding: 0 16px;
    font-size: 16px !important;
    font-weight: 400;
    line-height: 24px !important;
    width: 100%;
}

textarea {
    min-height: 96px;
    padding-top: 12px;
}

input.error,
textarea.error
{
    border: 2px solid #db4141;
}

input::placeholder,
textarea ::placeholder 
{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #728197;
}
textarea:focus,
input:focus {
    outline: none;
}

.error-msg {
    color: #db4141 !important;
    font-family: Inter;
    font-weight: 400 !important;
    font-size: 14px;
    padding-left: 16px;
    position: absolute;
    top: 100%;
}

/* Toast */
.custom-toast .toast {
    background: #004c45;
    position: fixed;
    bottom: 5%;
    right: 5%;
    z-index: 5;
}
.custom-toast .toast .toast-body {
    color: #fff;
    font-size: 14px;
    line-height: 1.2;
    font-weight: 400;
}
.custom-toast .toast .toast-header {
    background: #004c45;
}
.custom-toast .toast .toast-header strong {
    font-family: Inter;
    font-weight: 600;
    font-size: 18px;
    color: #fff;
}
.custom-toast .toast-header .btn-close {
    background: none;
    line-height: 0;
}
.custom-toast .toast-header .btn-close:focus {
    box-shadow: none;
}

/**********************
********Button*********
**********************/
.page .btn {
    height: 48px;
    padding: 0;
    border-radius: 8px;
}

.page .btn img {
    width: 24px;
    margin-left: 12px;
}
.page .btn-outline,
.page .btn-primary {
    background-color: #3CBD27 !important;
    border: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #fff;
    transition: all .5s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.page .btn-outline {
    background-color: transparent !important;
    border: 2px solid #138700;
}

.page .btn-primary:active,
.page .btn-primary:hover,
.page .btn-primary:focus-visible {
    background-color: #138700 !important;
    box-shadow: none;
}

.page .btn-outline:active,
.page .btn-outline:hover,
.page .btn-outline:focus-visible {
    background-color: #138700 !important;
    box-shadow: none;
}

/**********************
********Header*********
**********************/
.header {
    display: inline-block;
    width: 100%;
    height: 88px;
    z-index: 6;
    position: relative;
}

.header .navbar-main {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
}

.header .navbar {
    background: #022C28;
    padding: 0 55px;
    height: 88px;
    position: relative;
}

.header .navbar li a.active {
    color: #3CBD27 !important;
}

.header .navbar .navbar-brand {
    margin: 0;
    padding: 0;
    line-height: 0;
    width: 111px;
}

.header .navbar .navbar-nav.navbar-center {
    display: flex;
    flex-direction: row;
    background: #021513;
    border-radius: 8px;
    height: 40px;
    padding: 0 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.header .navbar .navbar-nav.navbar-center li a {
    font-size: 13.2px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: center;
    color: #F6F8FB;
    padding: 0;
    margin: 0 19px;
    height: 100%;
    display: flex;
    align-items: center;
}

.header .navbar .navbar-nav.navbar-center li button {
    background: transparent;
    padding: 0;
    border: 0;
    font-size: 13.2px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: center;
    color: #F6F8FB;
    margin: 0 19px;
    height: 100%;
}
.header .navbar .navbar-nav.navbar-center li button:hover {
    color: #3CBD27;
}

.header .navbar .navbar-nav.navbar-center li .dropdown-menu {
    position: absolute;
    margin: 0;
    padding: 15px;
    background: #021513;
}
.header .navbar .navbar-nav.navbar-center li .dropdown-menu  li a {
    margin: 0 0 5px;
    font-size: 13.2px;
    font-weight: 600;
    line-height: 16.8px;
    color: #F6F8FB;
    background: transparent !important;
}
.header .navbar .navbar-nav.navbar-center li .dropdown-menu  li a:hover {
    background: transparent;
}

.header .navbar .navbar-nav.navbar-right li a {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #FFFFFF;
}
.header .navbar .navbar-nav.navbar-right li a img {
    width: 15px;
    margin-left: 5px;
}

.header .navbar .nav-collapse {
    width: calc(100% - 111px);
    display: flex;
    justify-content: flex-end;
}

.header .navbar .navbar-toggle {
    display: none;
}

/**********************
********Footer*********
**********************/
.footer {
    padding: 120px 0;
}

.footer .col-lg-7 {
    max-width: 55.2%;
}

.footer .footer-links,
.footer .footer-details,
.footer .footer-newsletter {
    background: #153F3B;
    border-radius: 20px;
    padding: 32px;
}

.footer .footer-details h3,
.footer .footer-newsletter h3 {
    font-size: 22px;
    font-weight: 600;
    line-height: 24px;
    color: #F6F8FB;
    margin-bottom: 8px;
}

.footer .footer-details p,
.footer .footer-newsletter p {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #B6C2D3;
    margin-bottom: 24px;
}

.footer .footer-newsletter .form-group {
    position: relative;
}

.footer .footer-newsletter input {
    max-width: calc(100% - 164px);
}
.footer .footer-newsletter .error-msg {
    position: absolute;
    position: absolute;
    margin: 0;
    bottom: -20px;
    left: 0;
}

.footer .footer-newsletter .btn-primary {
    position: absolute;
    width: 148px;
    right: 0;
}

.footer .footer-links {
    width: calc(100% - 40px);
    margin-left: 40px;
}

.footer .footer-links h2 {
    font-size: 22px;
    font-weight: 600;
    line-height: 24px;
    color: #F6F8FB;
    margin-bottom: 33px;
}

.footer .footer-links ul li h2 {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    color: #F4F5F7;
    margin-bottom: 24px;
}

.footer .footer-links ul li a {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #EEEFF4;
    margin-bottom: 12px;
    display: inline-block;
}

.footer .footer-links ul li:last-child a {
    margin-bottom: 0;
}

.footer .footer-links ul li .dropdown-toggle {
    background: transparent;
    padding: 0;
    border: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #EEEFF4;
}
.footer .footer-links ul li .dropdown-menu {
    position: relative !important;
    transform: unset !important;
    width: 90%;
    min-width: unset;
    max-width: 150px;
    overflow: hidden;
    padding: 6px 10px;
    opacity: 0;
    visibility: hidden;
    height: 0;
    display: block;
    transition: all .5s ease;
    background: transparent;
    border: 0;
} 
.footer .footer-links ul li .dropdown-menu.show {
    opacity: 1;
    visibility: visible;
    height: 86px;
}
.footer .footer-links ul li .dropdown-menu li a {
    line-height: 1;
    margin-bottom: 0;
    padding: 0;
    color: #fff;
    font-size: 12px;
}
.footer .footer-links ul li .dropdown-menu li a.active {
    background: transparent;
    color: #3CBD27;
}
.footer .footer-links ul li .dropdown-menu li a:hover {
    background-color: transparent ;
}

.footer .col-lg-5 {
    max-width: 41%;
    margin-left: auto;
}

.footer .footer-details {
    margin-top: 40px;
}

.footer .footer-details .btn-primary {
    width: 194px;
}

.footer .footer-social {
    display: flex;
    align-items: center;
    margin-top: 62px;
}

.footer .footer-social p {
    color: #fff;
    margin: 0;
    font-size: 14px;
}

.footer .footer-social ul {
    display: flex;
    margin-left: auto;
}

.footer .footer-social ul li {
    margin-right: 15px;
}

.footer .footer-social ul li:last-child {
    margin-right: 0;
}

.footer .footer-social ul li a {
    display: inline-block;
    margin: 0;
}

.footer .footer-social ul li a:hover {
    opacity: .7;
}

.footer .footer-social ul li img {
    width: 35px;
}

/* Hero */
.hero {
    position: relative;
}

.hero .container {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
}

.hero .hero-heading h2 {
    font-family: Syne;
    font-size: 64px;
    font-weight: 600;
    line-height: 80px;
    letter-spacing: -2.4000000953674316px;
    color: #fff;
    margin: 0;
}

.hero .hero-heading h2 span {
    color: #3CBD27;
}

.hero .hero-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hero .hero-content p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #fff;
    margin-bottom: 24px;
}

.hero .hero-content .btn-primary {
    width: 165px;
}

/* Partners */
.brands {
    padding: 80px 120px;
    text-align: center;
    position: relative;
}

.brands h2 {
    font-size: 22px;
    font-weight: 600;
    line-height: 1.3;
    color: #D9FFC0;
    font-family: 'SYNE';
    /* margin-bottom: 44px; */
    margin-bottom: 80px;
}

.brands ul li {
    display: inline-block;
    margin: 0 80px 0 0;
}

.brands ul li:last-child {
    margin-right: 0;
}

/* Features */
.mobile {
    display: none;
}

.bg-glow {
    position: relative;
}

.bg-glow .bg-glow-inner {
    position: absolute;
    width: 100%;
}

.bg-glow .bg-glow-inner svg {
    width: 100%;
}

.features {
    position: relative;
    padding: 76px 0;
}

.features .features-inner {
    background: #153F3B;
    border: 1px solid #D4EDD8;
    border-radius: 40px;
    padding: 80px 100px;
}

.features .features-content {
    max-width: 508px;
    color: #fff;
}

.features .features-content h2 {
    font-family: Syne;
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
    margin-bottom: 4px;
}

.features .features-content p {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin: 0;
}

.features .features-content ul {
    margin-top: 32px;
    padding: 16px 24px 0;
    counter-reset: section;
}

.features .features-content ul li {
    margin-bottom: 26px;
    position: relative;
    padding-left: 44px;
}

.features .features-content ul li::before {
    counter-increment: section;
    content: counter(section);
    background: #3CBD27;
    border: 1px solid #D9FFC0;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 2px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.features .features-content ul li::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 9px;
    width: 2px;
    height: 40px;
    border-radius: 100px;
    background: rgba(255, 255, 255, .2);
}

.features .features-content ul li:last-child:after {
    display: none;
}

.features .features-content ul li:last-child {
    margin-bottom: 0;
}

.features .features-content ul li h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 6px;
}

.features .features-content ul li span {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 16px;
    display: inline-block;
}

.features .features-content ul li:last-child span {
    margin-bottom: 0;
}

.features .features-thumb {
    max-width: 364px;
    float: right;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Addon features */
.addon-features {
    position: relative;
}
.addon-features .addon-features-inner {
    background: #D4EDD8;
    border: 1px solid #D9FFC0;
    border-radius: 40px;
    padding: 80px 120px;
}

.addon-features .addon-features-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.addon-features .addon-features-content h2 {
    font-family: Syne;
    font-size: 48px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: -0.47999998927116394px;
    color: #004A2E;
    margin: 0 0 8px;
}

.addon-features .addon-features-content p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #1A212B;
    margin: 0 0 24px;
}

.addon-features .addon-features-content .btn-primary {
    width: 180px;
}

.addon-features .addon-features-list ul li {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}

.addon-features .addon-features-list ul li:last-child {
    margin-bottom: 0;
}

.addon-features .addon-features-list ul li img {
    width: 25%;
}

.addon-features .addon-features-list ul li div {
    padding-left: 24px;
    width: 75%;
}

.addon-features .addon-features-list ul li div h3 {
    font-size: 22px;
    font-weight: 600;
    line-height: 24px;
    color: #004A2E;
    margin: 0 0 4px;
}

.addon-features .addon-features-list ul li div span {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #728197;
    display: inline-block;
}

/* Decision */
.decision {
    padding: 64px 0;
}

.decision .mobile-cards {
    display: none;
}

.decision .decision-heading h2 {
    font-family: Syne;
    font-size: 48px;
    font-weight: 600;
    line-height: 57.6px;
    letter-spacing: -0.015em;
    color: #ECFAE3;
}

.decision .decision-heading h2 span {
    display: block;
    color: #3CBD27;
}

.decision .decision-slider .carousel-item-inner {
    background: #153F3B;
    border: 1px solid #D4EDD8;
    border-radius: 20px;
    padding: 40px;
}

.decision .decision-slider {
    padding-left: 120px;
    margin-top: 64px;
}

.decision .decision-slider .carousel-item-inner {
    min-height: 309px;
}

.decision .decision-slider .carousel-item-inner h3 {
    font-size: 22px;
    font-weight: 600;
    line-height: 24px;
    color: #ECFAE3;
    min-height: 70px;
    display: flex;
    align-items: center;
    margin: 0;
}

.decision .decision-slider .carousel-item-inner ul {
    min-height: 185px;
}

.decision .decision-slider .carousel-item-inner ul li {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #B6C2D3;
    position: relative;
    padding-left: 15px;
}

.decision .decision-slider .carousel-item-inner ul li::before {
    content: '';
    position: absolute;
    top: 8px;
    left: 0;
    width: 5px;
    height: 5px;
    background: #B6C2D3;
    border-radius: 100px;
}

.decision .decision-slider .carousel-item-inner .btn-primary {
    width: 199px;
}
.decision .decision-slider .carousel-item-inner .btn-primary:hover {
    color: #fff !important;
}

.decision .decision-slider .owl-stage {
    padding-left: 0 !important;
}

.decision .owl-carousel .owl-nav {
    margin: 0;
    position: absolute;
    top: calc(-100% - -126px);
    right: 120px;
}

.decision .owl-carousel .owl-nav .owl-next,
.decision .owl-carousel .owl-nav .owl-prev {
    background-color: #3CBD27;
    width: 50px;
    height: 50px;
    border-radius: 100px;
    margin: 0;
    background-size: 24px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    color: transparent;
    transition: all .3s ease;
}

.decision .owl-carousel .owl-nav .owl-next:hover,
.decision .owl-carousel .owl-nav .owl-prev:hover {
    background: #138700;
    color: transparent;
    background-size: 24px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.decision .owl-carousel .owl-nav .owl-next:hover,
.decision .owl-carousel .owl-nav .owl-next {
    background-image: url('../images/next.png');
}

.decision .owl-carousel .owl-nav .owl-prev:hover,
.decision .owl-carousel .owl-nav .owl-prev {
    background-image: url('../images/prev.png');
    margin-right: 8px;
}

/* FAQ */
.faq {
    padding: 120px 0;
}

.faq .faq-heading {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.faq .faq-heading h2 {
    font-size: 54.45px;
    font-weight: 700;
    line-height: 63.36px;
    letter-spacing: -0.47999998927116394px;
    color: #F6F8FB;
    margin-bottom: 8px;
}

.faq .faq-heading p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #F6F8FB;
}

.faq .faq-accordion .accordion-item {
    background: #fff;
    border: 0;
    border-radius: 12px;
    margin-bottom: 12px;
    overflow: hidden;
}

.faq .faq-accordion .accordion-button {
    height: 54.95px;
    padding: 0 24px;
    background: #fff;
    box-shadow: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #1A212B;
}

.faq .faq-accordion .accordion-button:not(.collapsed)::after {
    background-image: url('../images/bottom.png');
    background-size: 16px;
    background-position: 0 6px;
}

.faq .faq-accordion .accordion-button::after {
    background-image: url('../images/bottom.png');
    background-size: 16px;
    background-position: 0 6px;
}

.faq .faq-accordion .accordion-body {
    background: #fff;
    padding: 0 24px 18px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #728197;
}

/* Marquee */
.marq {
    background: rgba(255, 255, 255, .05);
}

.marq div {
    display: flex;
    height: 76px;
    align-items: center;
}

.marq img {
    width: 22px !important;
    margin: 0 40px;
}

.marq div p {
    margin: 0;
    font-family: Syne;
    font-size: 24px;
    font-weight: 400;
    line-height: 28.8px;
    color: #D4EDD8;
}

.ticker-wrap {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    white-space: nowrap;
    height: 60px;
    background: rgba(255, 255, 255, .05);
    display: flex;
    align-items: center;
}
.ticker-wrap .ticker {
    display: inline-block;
    animation: marquee 20s linear infinite;
}
.ticker-wrap .item-collection-1 {
    position: relative;
    left: 0%;
    animation: swap 20s linear infinite;
}
.ticker-wrap .item {
    display: inline-block;
    padding: 0 1rem;
    margin: 0;
    font-family: Syne;
    font-size: 24px;
    font-weight: 400;
    line-height: 28.8px;
    color: #D4EDD8;
}
.ticker-wrap .item img {
    margin: 0 40px;
    width: 22px;
}

/* Transition */
@keyframes marquee {
    0% {
        transform: translateX(0)
    }

    100% {
        transform: translateX(-100%)
    }
}

@keyframes swap {

    0%,
    50% {
        left: 0%;
    }

    50.01%,
    100% {
        left: 100%;
    }
}

/* Reviews */
.reviews {
    padding: 85px 0 50px;
}

.reviews .reviews-heading h2 {
    font-family: Syne;
    font-size: 32px;
    font-weight: 500;
    line-height: 42.24px;
    color: #fff;
    margin-bottom: 67px;
}

.reviews .container {
    padding: 0 68px;
}

.reviews .reviews-slider {
    padding-left: 40px;
}

.reviews .carousel-item-inner {
    padding: 0 30px 30px;
}

.reviews .carousel-item-inner div {
    background: #fff;
    border: 1px solid #3CBD27;
    border-radius: 20px;
    padding: 20px 57px;
}

.reviews .carousel-item-inner p::before {
    content: '';
    position: absolute;
    top: -5px;
    left: -34px;
    background-image: url('../images/Blockquote.png');
    background-size: 20px;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;

}

.reviews .carousel-item-inner p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #004A2E;
    min-height: 101px;
    position: relative;
    margin: 0;
}

.reviews .carousel-item-inner h3 {
    font-size: 16.5px;
    font-weight: 600;
    line-height: 19.36px;
    letter-spacing: -0.47999998927116394px;
    color: #001325;
    margin: 20px 0 0;
    min-height: 40px;
}

.reviews .carousel-item-inner h3 span {
    font-size: 14.88px;
    font-weight: 500;
    line-height: 17.6px;
    letter-spacing: -0.47999998927116394px;
    color: #728197;
    margin-left: 20px;
    display: block;
}

.reviews .carousel-item-inner .img-parent {
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: 65px;
    padding: 0;
    background: transparent;
    border: 4px solid rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 11px 0px rgba(208, 200, 187, 0.33);
    border-radius: 50%;
}

.reviews .owl-carousel .owl-stage {
    padding-left: 0 !important;
}

.reviews .owl-carousel .owl-nav {
    margin: 0;
    position: absolute;
    top: -55%;
    right: 120px;
}

.reviews .owl-carousel .owl-nav .owl-next,
.reviews .owl-carousel .owl-nav .owl-prev {
    background-color: #fff;
    width: 46px;
    height: 46px;
    border-radius: 100px;
    margin: 0;
    background-size: 9px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    color: transparent;
    transition: all .3s ease;
}

.reviews .owl-carousel .owl-nav .owl-next:hover,
.reviews .owl-carousel .owl-nav .owl-prev:hover {
    background: #adadad;
    color: transparent;
    background-size: 9px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.reviews .owl-carousel .owl-nav .owl-next:hover,
.reviews .owl-carousel .owl-nav .owl-next {
    background-image: url('../images/next-dark.png');
}

.reviews .owl-carousel .owl-nav .owl-prev:hover,
.reviews .owl-carousel .owl-nav .owl-prev {
    background-image: url('../images/prev-dark.png');
    margin-right: 16px;
}

.reviews .seen {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-top: 47px;
}

.reviews .seen p {
    margin: 0 138px 0 0;
    font-size: 16.17px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.47999998927116394px;
    color: #fff;
    width: 123px;
    height: 43px;
    border-radius: 100px;
    background: rgba(255, 255, 255, 0.09);
    display: flex;
    justify-content: center;
    align-items: center;
}

.reviews .seen ul li {
    display: inline-block;
    margin-right: 100px;
}

.reviews .seen ul li:last-child {
    margin-right: 0;
}

/* GDPR */
/* .gdpr {
    padding-top: 80px;
} */

.gdpr .gdpr-inner {
    background: linear-gradient(90.47deg, #092D2A 0.45%, #042623 98.89%);
    box-shadow: 0px 0px 100px 0px #03706466;
    border: 2px solid #1FBAA9;
    border-radius: 16px;
    padding: 49px 40px;
}

.gdpr .gdpr-inner h2 {
    font-size: 32px;
    font-weight: 600;
    line-height: 36px;
    color: #D4EDD8;
}

.gdpr .gdpr-inner ul {
    display: flex;
    height: 100%;
}

.gdpr .gdpr-inner ul li {
    display: flex;
    align-items: center;
    width: 33%;
}

.gdpr .gdpr-inner ul li img {
    width: 48px;
}

.gdpr .gdpr-inner ul li p {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #D9FFC0;
    padding-left: 8px;
}

/* GRIDS */
.esg-grid {
    padding: 190px 258px 130px;
    background-image: url('../images/grid-bg.png');
    background-size: cover;
    background-position: 0 0;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.esg-grid .esg-grid-main {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 24px;
    align-items: flex-end;
    justify-content: flex-start;
    margin-bottom: 24px;
}

.esg-grid .esg-grid-main.top {
    align-items: flex-start;
}

.esg-grid .esg-grid-main .layer {
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, #3CBD27, #3CBD27);
    position: absolute;
    overflow: hidden;
    border-radius: 16px;
}

.esg-grid .esg-grid-main .layer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.esg-grid .esg-grid-main div {
    border-radius: 14px;
}

.esg-grid .esg-grid-main div.one {
    position: relative;
    width: 260.25px;
}

.esg-grid .esg-grid-main div.one-inner {
    background: #3CBD27;
    width: 260.25px;
    height: 260.25px;
    padding: 21px;
    display: flex;
    align-items: flex-end;
    position: relative;
    z-index: 4;
}

.esg-grid .esg-grid-main div.one .layer-1 {
    top: -27px;
    left: 0;
    z-index: 3;
}

.esg-grid .esg-grid-main div.eight .layer-1::before,
.esg-grid .esg-grid-main div.four .layer-1::before,
.esg-grid .esg-grid-main div.three .layer-1:before,
.esg-grid .esg-grid-main div.one .layer-1:before {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

.esg-grid .esg-grid-main div.one .layer-2 {
    top: -54px;
    left: 0;
    z-index: 2;
}

.esg-grid .esg-grid-main div.eight .layer-2::before,
.esg-grid .esg-grid-main div.four .layer-2::before,
.esg-grid .esg-grid-main div.three .layer-2::before,
.esg-grid .esg-grid-main div.one .layer-2:before {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
}

.esg-grid .esg-grid-main div.one .layer-3 {
    top: -81px;
    left: 0;
    z-index: 1;
}

.esg-grid .esg-grid-main div.eight .layer-3::before,
.esg-grid .esg-grid-main div.four .layer-3::before,
.esg-grid .esg-grid-main div.three .layer-3::before,
.esg-grid .esg-grid-main div.one .layer-3:before {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
}

.esg-grid .esg-grid-main div.one p {
    font-family: Syne;
    font-size: 32px;
    font-weight: 400;
    line-height: 38.4px;
    letter-spacing: -0.9599999785423279px;
    margin: 0;
    color: #fff;
}

.esg-grid .esg-grid-main div.two {
    background: #FAE499;
    width: 355px;
    height: 278px;
    padding: 22px;
    display: flex;
    align-items: center;
}

.esg-grid .esg-grid-main div.two h2 {
    font-family: Syne;
    font-size: 36px;
    font-weight: 400;
    line-height: 43.2px;
    letter-spacing: -0.31090906262397766px;
    color: #012B27;
    margin: 0;
}

.esg-grid .esg-grid-main div.two h2 img {
    width: 26px;
    margin-right: 6px;
}

.esg-grid .esg-grid-main div.two h2 span {
    font-family: Syne;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.31090906262397766px;
    color: #012B27;
    margin-top: 8px;
    display: block;
}

.esg-grid .esg-grid-main div.three {
    width: 260.25px;
    position: relative;
}

.esg-grid .esg-grid-main div.three-inner {
    width: 260.25px;
    height: 260.25px;
    padding: 21px;
    background: #3CBD27;
    position: relative;
    z-index: 4;
}

.esg-grid .esg-grid-main div.three .layer-1 {
    top: 0;
    right: -27px;
    z-index: 3;
}

.esg-grid .esg-grid-main div.three .layer-2 {
    top: 0;
    right: -54px;
    z-index: 2;
}

.esg-grid .esg-grid-main div.three .layer-3 {
    top: 0;
    right: -81px;
    z-index: 1;
}

.esg-grid .esg-grid-main div.three h2 {
    font-size: 64px;
    font-weight: 500;
    line-height: 80px;
    letter-spacing: -0.9599999785423279px;
    color: #FAE499;
    margin: 0;
}

.esg-grid .esg-grid-main div.three p {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    color: #FFFFFF;
}

.esg-grid .esg-grid-main div.four {
    width: 260.25px;
    position: relative;
}

.esg-grid .esg-grid-main div.four-inner {
    width: 260.25px;
    height: 260.25px;
    padding: 21px;
    background: #3CBD27;
    position: relative;
    z-index: 4;
}

.esg-grid .esg-grid-main div.four .layer-1 {
    top: 0;
    left: -27px;
    z-index: 3;
}

.esg-grid .esg-grid-main div.four .layer-2 {
    top: 0;
    left: -54px;
    z-index: 2;
}

.esg-grid .esg-grid-main div.four .layer-3 {
    top: 0;
    left: -81px;
    z-index: 1;
}

.esg-grid .esg-grid-main div.four h2 {
    font-size: 120px;
    font-weight: 400;
    line-height: 145.23px;
    letter-spacing: -0.6399999856948853px;
    color: #FAE499;
}

.esg-grid .esg-grid-main div.four p {
    font-size: 24px;
    font-weight: 400;
    line-height: 29.05px;
    letter-spacing: -0.6399999856948853px;
    color: #fff;
}

.esg-grid .esg-grid-main div.five {
    width: 260.25px;
    height: 260.25px;
    padding: 21px;
    background: #3CBD27;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.esg-grid .esg-grid-main div.five h2 {
    font-size: 80px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: -0.9599999785423279px;
    color: #FAE499;
}

.esg-grid .esg-grid-main div.five p {
    font-size: 32px;
    font-weight: 300;
    line-height: 43.2px;
    letter-spacing: -0.9599999785423279px;
    color: #fff;
}

.esg-grid .esg-grid-main div.six {
    width: 355px;
    height: 260.25px;
    padding: 21.75px 22.5px 23.5px 21.5px;
    background: #ECFAE3;
}

.esg-grid .esg-grid-main div.six p {
    font-family: Noto Serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 31.2px;
    letter-spacing: -0.23999999463558197px;
    color: #004A2E;
}

.esg-grid .esg-grid-main div.six p img {
    width: 26px;
    margin-right: 6px;
}

.esg-grid .esg-grid-main div.six p span {
    display: block;
    margin-top: 10px;
}

.esg-grid .esg-grid-main div.seven {
    width: 355px;
    height: 316.25px;
    padding: 22px 22px 121.61px 22px;
    background: var(--Tea-Green, #D9FFC0);
    border: 1px solid var(--British-racing-green, #004A2E);
    margin-left: 92px;
}

.esg-grid .esg-grid-main div.seven p {
    font-family: Noto Serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 31.2px;
    letter-spacing: -0.23999999463558197px;
    color: #004A2E;
}

.esg-grid .esg-grid-main div.seven p img {
    width: 26px;
    margin-right: 6px;
}

.esg-grid .esg-grid-main div.seven p span {
    display: block;
    margin-top: 14px;
}

.esg-grid .esg-grid-main div.eight {
    width: 260.25px;
    position: relative;
}

.esg-grid .esg-grid-main div.eight-inner {
    width: 260.25px;
    height: 260.25px;
    padding: 22px;
    background: var(--wagening-Green, #3CBD27);
    display: flex;
    align-items: flex-end;
    position: relative;
    z-index: 4;
}

.esg-grid .esg-grid-main div.eight .layer-1 {
    bottom: -27px;
    left: 0;
    z-index: 3;
}

.esg-grid .esg-grid-main div.eight .layer-2 {
    bottom: -54px;
    left: 0;
    z-index: 2;
}

.esg-grid .esg-grid-main div.eight .layer-3 {
    bottom: -81px;
    left: 0;
    z-index: 1;
}

.esg-grid .esg-grid-main div.eight p {
    margin: 0;
    font-family: Syne;
    font-size: 32px;
    font-weight: 500;
    line-height: 38.4px;
    color: #fff;
}

/* Contact Us */
.contact-us {
    position: relative;
}
.contact-us .contact-us-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-top: 2px solid #0e3632;
    z-index: -1;
}
.contact-us .contact-us-heading {
    text-align: center;
    padding-top: 80px;
}
.contact-us .contact-us-heading h2 {
    font-size: 40px;
    font-weight: 600;
    line-height: 44px;
    color: #F6F8FB;
    margin: 0 0 8px;
}
.contact-us .contact-us-heading h2 span {
    display: block;
    font-size: 16px;
    font-weight: 900;
    line-height: 20px;
    letter-spacing: 3px;
    color: #3CBD27;
    text-transform: uppercase;
    margin-bottom: 12px;
}
.contact-us .contact-us-heading p {
    margin: 0 auto;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #B6C2D3;
    width: 64%;
}
.contact-us form {
    margin-right: auto;
    margin-left: auto;
    margin-top: 80px;
    width: 100%;
    max-width: 768px;
    border-radius: 20px;
    background: #153F3B;
    border: 1px solid var(--chinese-White, #D4EDD8);
    box-shadow: 0px 4px 4px 0px #00000040;
    padding: 80px;
}
.contact-us form h3 {
    font-size: 22px;
    font-weight: 600;
    line-height: 24px;
    color: #F6F8FB;
    margin: 0;
}
.contact-us form h3 span {
    display: block;
    margin-top: 8px;
    margin-bottom: 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}
.contact-us form .form-group-main {
    display: flex;
}
.contact-us form .form-group-main .form-group {
    margin-right: 20px;
    width: calc(100% - 20px);
}
.contact-us form .form-group-main .form-group:last-of-type {
    margin-right: 0;
}
.contact-us form .form-group {
    margin-bottom: 24px;
    position: relative;
    line-height: 1;
}
.contact-us form .form-group:last-of-type {
    margin-bottom: 0;
}
.contact-us form .form-group label {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #B6C2D3;
    margin-bottom: 4px;
}
.contact-us form .form-group button {
    width: 100%;
}
.contact-us .contact-us-details {
    margin-right: auto;
    margin-left: auto;
    margin-top: 80px;
    margin-bottom: 80px;
    width: 100%;
    max-width: 768px;
    background: linear-gradient(90.47deg, #092D2A 0.45%, #042623 98.89%);
    border: 2px solid #1FBAA9;
    border-radius: 16px;
    box-shadow: 0px 0px 100px 0px #03706466;
    padding: 30px 40px;
}
.contact-us .contact-us-details h3 {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: rgba(255,255,255,.6);
    border-bottom: 1px solid rgba(255,255,255,.1);
    padding-bottom: 16px;
    margin-bottom: 16px;
}
.contact-us .contact-us-details p {
    font-size: 22px;
    font-weight: 600;
    line-height: 24px;
    color: #fff;
    margin: 0;
}
.contact-us .contact-us-details p span a,
.contact-us .contact-us-details p span {
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #B6C2D3;
    margin-top: 8px;
}
.contact-us .contact-us-details .col-12 {
    padding: 0 25px;
}

/* Case Studies */
.case-studies .case-studies-thumb {
    padding: 0 80px;
    margin-top: 43px;
}
.case-studies .case-studies-thumb img {
    border-radius: 20px;
}
.case-studies .case-studies-content {
    color: #f8f8f8;
    padding: 0 200px;
    margin-top: 16px;
}
.case-studies .case-studies-content h2 {
    font-size: 36px;
    font-weight: 600;
    line-height: 40px;
    color: #F6F8FB;
    margin-top: 64px;
}
.case-studies .case-studies-content p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #F6F8FB;
    margin: 32px 0 40px;
}
.case-studies .case-studies-content ul li {
    font-family: Sen;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: #F6F8FB;
    opacity: .78;
    position: relative;
    padding-left: 14px;
}
.case-studies .case-studies-content ul li:before {
    content: '';
    position: absolute;
    top: 13px;
    left: 0;
    width: 6px;
    height: 6px;
    background: #F6F8FB;
    border-radius: 100px;
} 

/* Privacy Policy */
.policy .container {
    padding: 0 180px;
}
.policy .policy-heading {
    text-align: center;
    margin: 80px 0;
}
.policy .policy-heading h2 {
    font-size: 40px;
    font-weight: 600;
    line-height: 44px;
    color: #F6F8FB;
    margin: 0 0 12px;
}
.policy .policy-heading p {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    color: #B6C2D3;
}
.policy .policy-content {
    color: #F6F8FB;
}
.policy .policy-content h3 {
    font-family: Sen;
    font-size: 48px;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: -2px;
    color: #F6F8FB;
    margin: 64px 0 24px;
}
.policy .policy-content h4 {
    font-family: Sen;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0;
    color: #F6F8FB;
    margin: 0;
}
.policy .policy-content p {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    color: #728197;
    margin: 0;
}
.policy .policy-content ul {
    padding: 20px;
}
.policy .policy-content ul li {
    position: relative;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    padding-left: 10px;
    color: #728197;
}
.policy .policy-content ul li::before {
    content: '';
    position: absolute;
    top: 11px;
    left: 0;
    width: 5px;
    height: 5px;
    border-radius: 100px;
    background: #728197;
}

/* 404 Page */
.page-404 {
    margin-top: 80px;
    color: #f8f8f8;
}
.page-404 h1 {
    font-size: 140px;
    line-height: 1;
}
.page-404 p {
    font-size: 16px;
    line-height: 1.2;
    width: 43%;
    margin: 0 auto 40px;
}
.page-404 button {
    width: 140px;
}

/* Cookie Consent */
.cookie-consent .toast-body .btn-outline,
.cookie-consent .toast-body .btn-primary {
    width: 85px;
    height: 35px;
    font-size: 14px;
}
.cookie-consent .toast-body .btn-outline {
    margin-left: 10px;
}
.cookie-consent .cookiePolicyBtn {
    background: transparent;
    border: 0;
    padding: 0;
    color: #3CBD27;
}
.cookie-consent .cookiePolicyBtn:hover {
    opacity: .7;
}

/* Media Queries */
@media (min-width: 1600px) {

    /*************************
    ********Container*********
    *************************/
    .page .container {
        padding: 0 18.6%;
    }

    /**********************
    ********Header*********
    **********************/
    .header .navbar {
        padding: 0 10%;
    }

    /* .decision */
    .decision .decision-slider {
        padding: 0 18.6%;
    }

    .decision .owl-carousel .owl-nav {
        right: 0;
        top: -56%;
    }

    /* .reviews */
    .reviews .reviews-slider {
        padding: 0 18.6% 0 17.1%;
    }

    .reviews .owl-carousel .owl-nav {
        right: 0;
    }

    /* Case Studies */
    .case-studies .case-studies-thumb {
        padding: 0 10%;
    }
    .case-studies .case-studies-content {
        padding: 0 18%;
    }
}

@media (max-width: 1199px) {

    /**********************
    ********Footer*********
    **********************/
    .footer .col-lg-7 {
        max-width: unset;
    }

    .footer .col-lg-5 {
        max-width: 38%;
    }

    .footer .footer-social ul {
        display: flex;
    }

    .footer .footer-social ul li img {
        width: 30px;
    }

    .footer .footer-newsletter input {
        max-width: calc(100% - 163px);
    }

    .footer .footer-newsletter p br {
        display: none;
    }

    .footer .footer-social p {
        font-size: 12px;
    }

    .footer .footer-social ul li img {
        width: 25px;
    }

    .footer .footer-social ul li {
        margin-right: 10px;
    }

    .footer .footer-links h2 {
        line-height: 28px;
    }

    /* Hero */
    .hero .hero-heading h2 {
        font-size: 42px;
        line-height: 1.3;
    }

    /* Partners */
    .brands ul li {
        width: 25%;
        margin: 0;
        padding: 0 25px;
    }

    /* Features */
    .features .features-content {
        max-width: 100%;
    }

    .features .features-inner {
        padding: 60px;
    }

    .features .features-content h2 {
        font-size: 28px;
        line-height: 1;
    }

    .features .features-content ul li span br,
    .features .features-content p br {
        display: none;
    }

    .features .features-thumb {
        max-width: 80%;
    }

    .features .features-content ul {
        padding: 16px 0 0;
    }

    .features .features-content ul li span {
        font-size: 10px;
    }

    .features .features-content ul li::before {
        width: 15px;
        height: 15px;
        font-size: 10px;
        line-height: 15px;
    }

    .features .features-content ul li::after {
        height: 25px;
        left: 7px;
    }

    .features .features-content ul li {
        padding-left: 39px;
    }

    /* Addon features */
    .addon-features .addon-features-inner {
        padding: 60px;
    }

    .addon-features .addon-features-content h2 {
        font-size: 42px;
    }

    /* .decision */
    .decision .decision-heading h2 {
        font-size: 32px;
        line-height: 1.2;
    }

    .decision .owl-carousel .owl-nav {
        top: -140px;
    }

    .decision .owl-carousel .owl-nav .owl-next,
    .decision .owl-carousel .owl-nav .owl-prev {
        width: 40px;
        height: 40px;
    }

    .decision .decision-slider .carousel-item-inner h3 {
        font-size: 16px;
        line-height: 1.2;
    }

    .decision .decision-slider .carousel-item-inner ul li {
        font-size: 14px;
        line-height: 1.5;
    }

    /* GDPR */
    .gdpr .gdpr-inner h2 {
        font-size: 22px;
        line-height: 1.2;
    }

    .gdpr .gdpr-inner ul li img {
        width: 30px;
    }

    .gdpr .gdpr-inner ul li p {
        font-size: 10px;
        line-height: 1.2;
    }

    /* GRID */
    .esg-grid {
        padding: 0;
        height: 800px;
    }

    .esg-grid .esg-grid-inner {
        transform: scale(0.6);
        position: relative;
        top: -49px;
    }

    /* Case Studies */
    .contact-us.case-studies .contact-us-heading p {
        width: 100%;
    }
    .case-studies .case-studies-content {
        padding: 0 120px;
    }

    /* Privacy Policy */
    .policy .container {
        padding: 0 10%;
    }
    .policy .policy-content h3 {
        font-size: 38px;
        line-height: 1;
    }

    /* 404 Page */
    .page-404 p {
        width: 51%;
    }
}

@media (max-width:991px) {

    /**********************
    ********Footer*********
    **********************/
    .footer .footer-links {
        width: 100%;
        margin-left: 0;
    }

    .footer .col-lg-5 {
        max-width: 100%;
    }

    .footer .footer-links,
    .footer .footer-details {
        margin-top: 20px;
    }

    /* Hero */
    .hero .hero-bg img {
        height: 600px;
        object-fit: cover;
    }

    .hero .hero-heading h2 {
        margin-bottom: 10px;
    }

    /* Partners */
    .brands {
        padding: 80px 0;
    }

    /* Features */
    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }

    .features .features-thumb {
        max-width: 80%;
        float: unset;
        margin: 0 auto;
    }

    .features .features-content {
        margin-bottom: 40px;
    }

    /* Addon features */
    .addon-features .addon-features-content .btn-primary {
        margin-bottom: 40px;
    }

    /* .decision */
    .decision .decision-slider .owl-carousel {
        display: none;
    }

    .decision .mobile-cards {
        display: block;
    }

    .decision .decision-slider {
        padding: 0 120px;
    }

    .decision .decision-slider .mobile-cards .carousel-item-inner {
        margin-bottom: 40px;
    }

    .decision .decision-slider .mobile-cards .carousel-item-inner:last-of-type {
        margin-bottom: 0;
    }

    .decision .decision-slider .carousel-item-inner h3 {
        min-height: unset;
        margin-bottom: 10px;
    }

    .decision .decision-slider .carousel-item-inner ul {
        min-height: unset;
        margin-bottom: 40px;
    }

    .decision .decision-slider .mobile-cards .carousel-item-inner {
        min-height: unset;
    }

    /* FAQ */
    .faq .faq-heading {
        margin-bottom: 40px;
    }

    /* Reviews */
    .reviews .reviews-heading h2 {
        width: 60%;
    }

    .reviews .owl-carousel .owl-nav {
        top: -65%;
    }

    .reviews .seen ul li {
        margin-right: 0;
        width: 33%;
        padding-left: 40px;
    }

    .reviews .seen p {
        margin: 0 100px 0 0;
    }

    /* GDPR */
    .gdpr .gdpr-inner h2 {
        margin-bottom: 20px;
        font-size: 28px;
    }

    .gdpr .gdpr-inner ul {
        flex-direction: column;
    }

    .gdpr .gdpr-inner ul li {
        width: 100%;
        margin: 0 0 32px;
    }

    .gdpr .gdpr-inner ul li:last-child {
        margin-bottom: 0;
    }

    .gdpr .gdpr-inner ul li img {
        width: 48px;
    }

    .gdpr .gdpr-inner ul li p {
        font-size: 14px;
        margin-left: 10px;
    }

    /* Contact Us */
    .contact-us .contact-us-heading p {
        width: 100%;
    }

    /* Case Studies */
    .contact-us.case-studies .contact-us-heading h2 br {
        display: none;
    }
    .case-studies .case-studies-content h2 {
        font-size: 28px;
        line-height: 1.2;
    }

    /* 404 Page */
    .page-404 p {
        width: 85%;
    }
    
}

@media (max-width: 767px) {

    /**********************
    ********Container******
    **********************/
    .page .container {
        padding: 0 10%;
    }

    /**********************
    ********Footer*********
    **********************/
    .footer {
        padding: 20px 0;
    }

    /**********************
    ********Header*********
    **********************/
    .header {
        height: 64px;
    }

    .header .navbar {
        height: 64px;
        padding: 0 20px;
    }

    .header .navbar .navbar-brand {
        width: 101px;
    }

    .header .navbar .navbar-nav.navbar-center {
        position: unset;
        top: unset;
        left: unset;
        transform: unset;
        display: block;
        background: no-repeat;
        padding: 0;
        height: auto;
        border-radius: unset;
    }

    .header .navbar .navbar-nav.navbar-center li a {
        display: inline-block;
        margin: 0 0 25px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
    }
    .header .navbar .navbar-nav.navbar-center li.dropdown {
        margin-bottom: 25px;
    }
    .header .navbar .navbar-nav.navbar-center li button {
        display: inline-block;
        margin: 0;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
    }
    .header .navbar .navbar-nav.navbar-center li .dropdown-menu {
        position: relative;
        margin: 0 auto;
        padding: 15px;
        background: #021513;
        width: 100%;
        max-width: 100px;
        text-align: center;
    }

    .header .navbar .nav-collapse {
        display: flex;
        flex-direction: column;
        justify-content: unset;
        position: absolute;
        top: 100%;
        left: -100%;
        width: 100%;
        height: calc(100vh - 64px);
        background: #042D2A;
        padding: 40px 15px;
        transition: all .3s ease;
        text-align: center;
        z-index: 10;
    }

    .header .navbar .nav-collapse.nav-slide {
        left: 0;
    }

    .header .navbar .navbar-nav.navbar-right {
        margin-top: auto;
    }

    .header .navbar .navbar-nav.navbar-right li a {
        padding: 0;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
    }

    .header .navbar .navbar-toggle {
        display: block;
        padding: 0;
        border: 0;
        background: transparent;
        line-height: 0;
        width: 13px;
    }

    /* Brands */
    .features {
        padding: 20px 0;
    }
    .brands {
        padding: 60px 0 20px;
    }

    /* Features */
    .features .features-inner {
        padding: 32px;
        border-radius: 20px;
    }

    /* Addon Features */
    .addon-features .addon-features-inner {
        padding: 32px;
        border-radius: 20px;
    }

    .addon-features .addon-features-list ul li img {
        width: 20%;
    }

    /* .decision */
    .decision .decision-slider {
        padding: 0 10%;
    }

    /* FAQ */
    .faq {
        padding: 60px 0;
    }

    /* .reviews */
    .reviews .owl-carousel .owl-nav {
        top: -77%;
        right: 10%;
    }

    .reviews .seen p {
        margin: 0 15px 0 0;
    }

    /* GDPR */
    .gdpr {
        padding-top: 8px;
        margin-bottom: 29px;
    }

    /* GRID */
    .esg-grid {
        height: 650px;
        margin: 0;
    }

    .esg-grid .esg-grid-inner {
        transform: scale(0.5);
        top: -126px;
    }

    /* Contact Us */
    .contact-us form .form-group-main {
        display: unset;
    }
    .contact-us form .form-group-main .form-group {
        width: 100%;
        margin-right: 0;
    }
    .contact-us form .form-group-main .form-group:last-of-type {
        margin-bottom: 24px;
    }
    .contact-us form,
    .contact-us .contact-us-details {
        padding: 32px;
    }
    .contact-us .contact-us-details .row {
        margin: 0;
    }
    .contact-us .contact-us-details .col-12 {
        padding: 0;
        margin-bottom: 24px;
    }
    .contact-us .contact-us-details .col-12:last-of-type {
        margin-bottom: 0;
    }
    .contact-us .contact-us-heading {
        padding-top: 40px;
    }
    .contact-us .contact-us-heading h2 {
        font-size: 32px;
    }
    .contact-us form {
        margin-top: 40px;
    }

    /* Case Studies */
    .case-studies .case-studies-content {
        padding: 0 10%;
    }
    .case-studies .case-studies-thumb {
        padding: 0 10%;
    }
    .case-studies .case-studies-thumb img {
        height: 300px;
        object-fit: cover;
        object-position: center;
        border-radius: 20px;
    }
    .case-studies .case-studies-content ul li {
        font-size: 18px;
        line-height: 1.2;
    }
    .case-studies .case-studies-content ul li:before {
        top: 8px;
    }

    /* 404 Page */
    .page-404 {
        margin: 40px 0;
    }
    .page-404 p {
        width: 100%;
    }
}

@media (max-width: 576px) {

    /*****************************
    ********Form Elements*********
    *****************************/
    input {
        height: 40px;
    }

    /**********************
    ********Container******
    **********************/
    .page .container {
        padding: 0 25px;
    }

    /* Button Style */
    .page .btn {
        height: 40px;
    }

    /**********************
    ********Footer*********
    **********************/
    .footer .footer-details p br,
    .footer .footer-newsletter p br {
        display: none;
    }

    .footer .footer-newsletter input {
        max-width: calc(100% - 104px);
    }

    .footer .footer-newsletter .btn-primary {
        width: 97px;
    }

    .footer .footer-links .col-6 {
        width: 100%;
    }

    .footer .footer-links .second {
        margin-top: 20px;
    }

    /* Hero */
    .hero .hero-heading h2 {
        font-size: 40px;
        margin-bottom: 20px;
    }

    .hero .hero-content p {
        margin-bottom: 42px;
    }

    .hero .hero-bg img {
        object-position: 0;
        height: calc(100vh - 64px);
    }

    .hero .hero-content .btn-primary {
        width: 190px;
    }

    /* Partners */
    .brands h2 {
        margin-bottom: 40px;
    }
    .brands ul {
        padding: 0 20px 20px;
    }

    .brands ul li {
        width: 100%;
        margin: 0 0 64px;
        padding: 0;
    }

    .brands ul li:last-child {
        margin-bottom: 0;
    }

    .brands ul li img {
        width: auto;
    }

    /* Features */
    .bg-glow .bg-glow-inner {
        top: 10%;
    }

    .features .features-inner {
        padding: 20px 22px;
    }

    .features .features-content h2 {
        font-size: 32px;
        line-height: 48px;
    }

    .features .features-content ul li::before {
        width: 20px;
        height: 20px;
        font-size: 14px;
        line-height: 20px;
    }

    .features .features-content ul li::after {
        height: 40px;
        left: 9px;
    }

    .features .features-content ul li span:last-of-type {
        margin-bottom: 16px;
    }

    .features .features-content ul li span {
        font-size: 12px;
    }

    .features .features-thumb {
        max-width: 75%;
    }

    /* Addon features */
    .addon-features .addon-features-inner {
        padding: 25px;
    }

    .addon-features .addon-features-content h2 {
        font-size: 32px;
        line-height: 32px;
    }

    .addon-features .addon-features-content p {
        font-size: 12px;
        line-height: 18px;
    }

    .addon-features .addon-features-content .btn-primary {
        width: 100%;
    }

    .addon-features .addon-features-list ul li img {
        width: 25%;
    }

    .addon-features .addon-features-list ul li div h3 {
        font-size: 12px;
        line-height: 16px;
    }

    .addon-features .addon-features-list ul li div span {
        font-size: 10px;
        line-height: 12px;
    }

    .addon-features .addon-features-list ul li div {
        padding-left: 13px;
    }

    /* .decision */
    .decision .decision-heading h2 {
        font-size: 20px;
        line-height: 24px;
    }

    .decision .decision-slider {
        padding: 0 25px;
        margin-top: 20px;
    }

    .decision .decision-slider .mobile-cards .carousel-item-inner {
        padding: 20px;
    }

    .decision .decision-slider .carousel-item-inner h3 {
        font-size: 22px;
        line-height: 24px;
    }

    .decision .decision-slider .carousel-item-inner ul li {
        font-size: 16px;
        line-height: 24px;
    }

    .decision .decision-slider .carousel-item-inner ul {
        margin-bottom: 20px;
    }

    /* FAQ */
    .faq .faq-heading {
        margin-bottom: 25px;
    }

    /* .reviews */
    .reviews {
        padding: 40px 0 20px;
    }
    .reviews .reviews-heading h2 {
        width: 100%;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 24px;
    }

    .reviews .reviews-slider {
        padding-left: 25px;
        margin-left: -10px;
    }

    .reviews .carousel-item-inner {
        padding: 0 10px 20px;
    }

    .reviews .carousel-item-inner p {
        font-size: 12px;
        line-height: 13px;
        min-height: 51px;
    }

    .reviews .carousel-item-inner p::before {
        top: 0;
        left: -15px;
        background-size: 10px;
        width: 10px;
        height: 10px;
    }

    .reviews .carousel-item-inner h3 span,
    .reviews .carousel-item-inner h3 {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
    }

    .reviews .carousel-item-inner div {
        padding: 10px 15px 10px 30px;
    }

    .reviews .carousel-item-inner .img-parent {
        width: 40px;
        left: 0;
        bottom: 15px;
    }

    .reviews .seen {
        margin-top: 27px;
        flex-direction: column;
    }

    .reviews .seen p {
        margin: 0 0 36px;
    }

    .reviews .seen ul li {
        width: 100%;
        display: block;
        padding-left: 0;
        margin-bottom: 40px;
    }

    .reviews .seen ul li:last-child {
        margin-bottom: 0;
    }

    /* GDPR */
    .gdpr .gdpr-inner {
        padding: 20px;
    }

    .gdpr .gdpr-inner h2 {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 32px;
    }

    /* GRID */
    .esg-grid {
        height: unset;
        padding: 145px 50px;
    }

    .esg-grid .esg-grid-inner {
        transform: unset;
        top: 0;
    }

    .esg-grid .esg-grid-main {
        grid-template-columns: repeat(1, auto);
        grid-gap: 16px;
        margin-bottom: 16px;
    }

    .esg-grid .esg-grid-main div {
        width: 100% !important;
    }

    .esg-grid .esg-grid-main div.one-inner,
    .esg-grid .esg-grid-main div.two-inner,
    .esg-grid .esg-grid-main div.three-inner,
    .esg-grid .esg-grid-main div.four-inner,
    .esg-grid .esg-grid-main div.five-inner,
    .esg-grid .esg-grid-main div.six-inner,
    .esg-grid .esg-grid-main div.seven-inner,
    .esg-grid .esg-grid-main div.eight-inner {
        padding: 22px !important;
    }

    .esg-grid .esg-grid-main div.seven {
        margin-left: 0;
    }

    .esg-grid .esg-grid-main div.three .layer,
    .esg-grid .esg-grid-main div.four .layer {
        display: none;
    }

    .esg-grid .esg-grid-main div.two {
        height: 212px;
    }

    .esg-grid .esg-grid-main div.two h2 {
        font-size: 22px;
        line-height: 31px;
    }

    .esg-grid .esg-grid-main div.four h2 {
        font-size: 64px;
        line-height: 77px;
    }

    .esg-grid .esg-grid-main div.five h2 {
        font-size: 64px;
    }

    .esg-grid .esg-grid-main div.five p {
        font-size: 22px;
        margin: 0;
    }

    .esg-grid .esg-grid-main div.seven,
    .esg-grid .esg-grid-main div.six {
        height: 278px;
    }

    .esg-grid .esg-grid-main div.seven p img,
    .esg-grid .esg-grid-main div.six p img {
        margin-right: 0;
        display: block;
        margin-bottom: 27px;
    }

    .esg-grid .esg-grid-main div.seven p {
        font-size: 20px;
        margin: 0;
    }

    .esg-grid .esg-grid-main div.seven p img {
        margin-bottom: 12px;
    }

    .esg-grid .esg-grid-main div.eight p {
        line-height: 44px;
    }

    .esg-grid .esg-grid-main div.eight-inner {
        align-items: center;
    }

    /* Contact Us */
    .contact-us .contact-us-heading h2 {
        font-size: 22px;
        line-height: 1;
    }
    .contact-us .contact-us-heading p {
        font-size: 12px;
        font-weight: 400;
        line-height: 1.2;
    }
    .contact-us .contact-us-bg img {
        height: 320px;
        object-fit: cover;
    }

    /* Privacy Policy */
    .policy .policy-heading {
        margin: 40px 0;
    }
    .policy .policy-content h3 {
        font-size: 28px;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    /* 404 Page */
    .page-404 h1 {
        font-size: 130px;
    }

    /* Custom Toast */
    .custom-toast .toast {
        right: unset;
        width: 90%;
        left: 50%;
        transform: translateX(-50%);
    }
}